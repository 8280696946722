import React from 'react';

import { Heading } from 'components/v2/Typography';
import { Row, Column } from 'styled/Grid';

import { DetailsOfRetrieval } from './DetailsOfRetrieval/DetailsOfRetrieval';
import { Section } from './EggRetrieval.styled';
import { CultureResult } from './EggRetrieval.types';

export const EggRetrieval = ({ cultureResult }: { cultureResult: CultureResult }) => {
  return (
    <Section>
      <Heading tag="h4">Details of Egg Retrieval and Fertilization</Heading>

      <Row>
        <Column mobile={12}>
          <DetailsOfRetrieval cultureResult={cultureResult} />
        </Column>
      </Row>
    </Section>
  );
};
